<template>
  <div>
    <h3 ref="intro">
      Conic Sections: What Are They?
    </h3>
    <p>
      Do you know what  circles, ellipses, parabolas, and hyperbolas have in common &mdash; they all belong to the same family of shapes called conic sections.
      A conic section, as its name suggests, is a shape generated by intersection of a plane with a cone.
    </p>
    <h3 ref="sol">
      Cutting Plane Orientation
    </h3>
    <p>
      The orientation of the intersecting plane relative to the axis of the cone determines the type of the conic section (i.e. circular, elliptical, parabolic or hyperbolic) that will be generated.
      For example:
      <ul style="list-style-type:square;">
        <li>
          <h5> Plane Perpendicular to Cone Axis </h5>
          A cone intersected by a plane perpendicular to the axis of the cone gives a circular conic section.
        </li>
        <li>
          <h5> Plane Parallel to Generating Line  </h5>
          The conic section generated by a plane parallel to the generating line of the cone is a parabola.
        </li>
        <li>
          <h5> Plane Parallel to Cone Axis </h5>
          The conic section generated by a plane parallel to the axis of cone is a hyperbola.
        </li>
      </ul>
    </p>
    <h3 ref="graph">
      PlayGraph: Let's Create Conic Sections!
    </h3>
    <p />
    <v-responsive
      :aspect-ratio="1"
    >
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'ConicSections',
  created: function () {
    // Store mutations
    this.$store.commit('navigation/resetState');
    let title = 'Conic Sections';
    this.$store.commit('navigation/changeTitle', 'Conic Sections');
    this.$store.commit('navigation/changeMenu', 'Conic Sections');
    let newTopics = [
      {title: 'Conic Sections', img:'/assets/number-1.svg', action: () => this.goto('intro')},
      {title: 'Orientation of the Cutting Plane', img:'/assets/number-2.svg', action: () => this.goto('sol')},
      {title: 'PlayGraph: Generating Conic Sections', img:'/assets/touch.svg', action: () => this.goto('graph')}
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Conic Section',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively how to draw a free body diagram'}
                ]
        }
   },
}
</script>

<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
</style>
