const Boxes = {
  box1: function () {
		var brd2 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-13, 13, 13, -13],keepaspectratio: true, axis:false, ticks:true, grid:true, pan:{enabled:false}, zoom:{enabled:false}, showCopyright:false, showNavigation:false});
		var resize = function () {
        brd2.resizeContainer(brd2.containerObj.clientWidth, brd2.containerObj.clientHeight, true);
        brd2.fullUpdate();
        };
		window.onresize = resize;
		brd2.options.layer['curve'] =3;
		brd2.options.layer['image'] =8;
		brd2.options.layer['segment'] =8;
		brd2.options.layer['line'] =6;
		brd2.options.layer['point'] =12;
		brd2.options.layer['polygon'] =3;
		brd2.create('text', [-3, 11, '<b>Conic Sections</b>'], {fixed:true, fontSize:function(){return 28*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
		var axi = brd2.create('segment', [[0,0], [0, 4.9]], {visible:true, dash:1, strokeColor:'black', fixed:true});
		var ptA = brd2.create('glider', [0, 1, axi], {face:'square', size:function(){return 3*brd2.canvasHeight/800}, name:'', strokeColor:'black', label:{fontSize:function(){return 18*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
		var circ = brd2.create('arc', [ptA, [9, function(){return ptA.Y()}], [0,function(){return ptA.Y()+9}]], {visible:false, strokeWidth:0.5, strokeColor:'black', dash:1, center:{visible:false}, radiuspoint:{visible:false}, anglepoint:{visible:false}});
//Point B
		var ptB = brd2.create('glider', [9, 1, circ], {face:'circle', size:function(){return 3*brd2.canvasHeight/800}, name:'', strokeColor:'black', label:{fontSize:function(){return 18*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
		var vel =brd2.create('angle', [[9, function(){return ptA.Y()}], ptA, ptB],{visible:false});
		var ptC = brd2.create('point', [function(){return -ptB.X();},function(){return -ptB.Y()+2*ptA.Y();}], {visible:false});
		var ptD = brd2.create('point', [function(){return ptC.X()+5*Math.cos(4.5*Math.PI/6);},function(){return ptC.Y() + 5*Math.sin(4.5*Math.PI/6);}], {visible:false});
		var ptE = brd2.create('point', [function(){return ptB.X()+5*Math.cos(4.5*Math.PI/6);},function(){return ptB.Y() + 5*Math.sin(4.5*Math.PI/6);}], {visible:false});
		brd2.create('segment', [ptC, ptD], {visible:true, strokeWidth:1, dash:1, strokeColor:'black'});
		brd2.create('segment', [ptB, ptE], {visible:true, strokeWidth:1, dash:1, strokeColor:'black'});
		brd2.create('segment', [ptD, ptE], {visible:true, strokeWidth:1, dash:1, strokeColor:'black'});
		brd2.create('image', ['/assets/cone.svg', [7., 7.],[14, 7.]], {rotate:180, fixed:true, opacity:0.7});
		brd2.create('image', ['/assets/random.svg', [function(){return ptC.X()}, function(){return ptC.Y()+5}],[2., 2.]], {rotate:155, fixed:true, opacity:0.7});
		brd2.create('image', ['/assets/curved-arrow.svg', [5.8, 5.4],[2., 2.]], {fixed:true, opacity:0.7});
		brd2.create('segment', [[0, 0], [6, 6]], {visible:true,strokeWidth:4, strokeColor:'black', fixed:true});
		brd2.create('segment', [[0, 0], [-6, 6]], {visible:true,strokeWidth:4, strokeColor:'black', fixed:true});
		brd2.create('image', ['/assets/rotate.svg', [function(){return ptB.X()+0.4}, function(){return ptB.Y()+0.4}],[0.8, 0.8]], {rotate:180});
		brd2.create('segment', [ptC, ptB], {visible:true, strokeWidth:1, dash:1, strokeColor:'black'});
		brd2.create('arrow', [[0,0], [0, 2]], {strokeColor:'black', strokeWidth:3, dash:1, fixed:true});
		brd2.create('arrow', [[0,0], [2, 0]], {strokeColor:'black', strokeWidth:3, dash:1, fixed:true});
		brd2.create('arrow', [[0,0], [-2*Math.cos(4.5*Math.PI/6), -2*Math.sin(4.5*Math.PI/6)]], {strokeColor:'black', strokeWidth:3, dash:1, fixed:true});
		brd2.create('image', ['/assets/move.svg', [function(){return ptA.X()+0.4}, function(){return ptA.Y()+0.4}],[0.8, 0.8]], {rotate:180});
		brd2.create('curve', [function(x){return Math.max(ptA.Y(),0)/(1-Math.tan(vel.Value())*Math.cos(x))},[0, -6], 0, 2*Math.PI], {curveType:'polar', strokeColor:'black', strokeWidth:0.75, dash:1, fillColor:'#4dd0e1', fillOpacity:1, fixed:true});
		brd2.create('polygon', [[function(){return(6-ptA.Y())/(((ptB.Y()-ptA.Y()+0.0001)/(ptB.X()-ptA.X())))},-40],[function(){return(6-ptA.Y())/(((ptB.Y()-ptA.Y()+0.0001)/(ptB.X()-ptA.X())))},40], [16, 40], [16, -40]], {vertices:{visible:false}, withLines:false, fillColor:'oldLace', fillOpacity:0, fixed:true});
		brd2.create('polygon', [[function(){return -ptA.Y()/Math.abs(1-Math.tan(vel.Value()))},-40],[function(){return -ptA.Y()/Math.abs(1-Math.tan(vel.Value()))},40], [-16, 40], [-16, -40]], {vertices:{visible:false}, withLines:false, fillColor:'oldLace', fillOpacity:0, fixed:true});
		brd2.create('polygon', [ptC, ptB,  ptE, ptD], {vertices:{visible:true}, withLines:false, borders:{strokeColor:'black', strokeWidth:2, dash:1}, fillColor:'grey', fillOpacity:0.4, fixed:true});
		brd2.create('ellipse', [[5.81, 6], [-5.81, 6],[0, 7.1]], {visible:true,strokeWidth:4, strokeColor:'black', fixed:true});
		brd2.create('text', [-10, -5, function(){return 'Eccentricity =' + Math.tan(vel.Value()).toFixed(1)}], {fixed:true, fontSize:function(){return 22*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
		brd2.create('text', [-10, -7, function(){if(Math.tan(vel.Value()) ==0){return 'Conic section is a Circle!'} else if (Math.tan(vel.Value()) < 1 ){return 'Conic section is an Ellipse!'} else if (Math.tan(vel.Value()) < 1.05 ){return 'Conic section is a Parabola!'} else {return 'Conic section is a Hyperbola!'}}], {fixed:true, fontSize:function(){return 22*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
		brd2.create('text', [7.4, 7.8,'Cone'], {fixed:true, fontSize:function(){return 22*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
		brd2.create('text', [function(){return ptD.X()+0.5}, function(){return ptD.Y()+2.75},'Plane'], {fixed:true, fontSize:function(){return 22*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});

    },
    }
export default Boxes;
